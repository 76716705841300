import React from 'react'
import Layout from '../components/layout'

class Fragen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Fragen? | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Wenn Sie Fragen haben…</h2>
              </header>
              <div>
                <ul>
                  <li>zu meiner Person</li>
                  <li>zur Psychotherapie</li>
                  <li>
                    zu den Abrechnungsmöglichkeiten in der Gesetzlichen oder
                    Privaten Krankenversicherung
                  </li>
                  <li>
                    zur Frage, ob ich für Ihr Problem die „Richtige“ sein
                    könnte…
                  </li>
                </ul>
                <p>… telefonieren Sie mit mir.</p>
                <p>
                  Sollte ich nicht erreichbar sein, sprechen Sie bitte auf die
                  Mailbox, ich rufe Sie dann möglichst am selben oder nächsten
                  Tag zurück.
                </p>
                <p>
                  Oder Sie nehmen per email mit mir Kontakt auf unter:{' '}
                  <a href="mailto:a.behrenberg@psychotherapie-telgte.de">
                    a.behrenberg(at)psychotherapie-telgte.de
                  </a>
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Fragen
